@font-face {
  font-family: 'Tungsten';
  src: url("../assets/fonts/Tungsten-Semibold.eot");
  src: url("../assets/fonts/Tungsten-Semibold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Tungsten-Semibold.woff2") format("woff2"), url("../assets/fonts/Tungsten-Semibold.woff") format("woff"), url("../assets/fonts/Tungsten-Semibold.ttf") format("truetype"), url("../assets/fonts/Tungsten-Semibold.svg#Tungsten-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

body footer {
  background-color: #2b2f33;
  text-align: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }
  body footer .social img.footerLogo {
    padding-top: 1rem;
    width: 9rem;
    height: 3rem;
    margin-right: 15px; }
    @media screen and (max-width: 390px) {
      body footer .social img.footerLogo {
        display: block;
        margin-bottom: 1rem; } }
  body footer .social a {
    margin-right: 5px; }
    body footer .social a .socialIcon {
      width: 2rem; }
  body footer .copyright {
    font-size: .9rem;
    padding-top: 1rem;
    color: #fff; }
  body footer > nav {
    padding-top: 1rem; }
    body footer > nav > a {
      font-size: 0.9rem;
      text-decoration: none;
      color: #60b3df; }
      body footer > nav > a + a {
        margin-left: 1em;
        position: relative; }
        body footer > nav > a + a:before {
          content: "";
          position: absolute;
          top: 0;
          left: -0.7em;
          width: 1px;
          height: 85%;
          background-color: #60b3df;
          pointer-events: none; }
  @media screen and (max-width: 640px) {
    body footer {
      padding: 2rem 0.75rem;
      padding-bottom: 4rem;
      display: block; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    body footer {
      padding: 2.5rem;
      padding-bottom: 6rem;
      display: block; } }
  @media screen and (min-width: 901px) {
    body footer {
      padding: 2rem calc(50vw - 40rem) 3rem; } }
  @media screen and (max-width: 1300px) {
    body footer {
      padding: 2rem 2rem 3rem; } }

body header {
  background: #fff;
  overflow: hidden; }
  body header .headerInner {
    max-width: 1200px;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 13%; }
    body header .headerInner .proofpoint img {
      width: 9rem;
      height: 3rem; }
      @media screen and (max-width: 420px) {
        body header .headerInner .proofpoint img {
          width: 7rem; } }
    body header .headerInner .navigation {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      padding-left: 2rem; }
      body header .headerInner .navigation a {
        font-family: Helvetica, sans-serif;
        padding-right: 2rem;
        font-size: 0.85rem;
        text-decoration: none;
        color: #000;
        text-transform: uppercase; }
        @media screen and (max-width: 445px) {
          body header .headerInner .navigation a {
            font-size: 0.75rem; } }
        @media screen and (max-width: 390px) {
          body header .headerInner .navigation a {
            padding-right: 1rem; } }
        body header .headerInner .navigation a:hover {
          color: #01579b; }
    @media screen and (max-width: 640px) {
      body header .headerInner {
        padding: 1.8rem 0.75rem;
        text-align: center; }
        body header .headerInner a {
          display: block; } }
    @media screen and (min-width: 641px) and (max-width: 900px) {
      body header .headerInner {
        padding: 1.8rem 2.5rem;
        text-align: center; }
        body header .headerInner a {
          display: block; } }
    @media screen and (min-width: 901px) {
      body header .headerInner {
        padding: 2.25rem 0rem;
        padding: 1rem 1rem; }
        body header .headerInner:after {
          content: " ";
          visibility: hidden;
          display: block;
          font-size: 0;
          clear: both;
          height: 0; }
        body header .headerInner :first-child {
          float: left; } }

article.asset-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  z-index: 2;
  position: relative;
  background-color: black; }
  article.asset-form > section {
    width: 100%; }
    article.asset-form > section .form .formHeader {
      text-align: center; }
      article.asset-form > section .form .formHeader .subhead {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 300;
        color: white;
        font-size: 1.2rem; }
      article.asset-form > section .form .formHeader h3 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        color: white;
        font-size: 2rem;
        margin-top: 1rem;
        margin-bottom: 2.5rem; }
    article.asset-form > section form {
      width: 60%;
      margin: 0 auto; }
      article.asset-form > section form .fieldset {
        margin-bottom: 1rem; }
        article.asset-form > section form .fieldset.two-up {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
              justify-content: space-between; }
          article.asset-form > section form .fieldset.two-up input {
            width: 48%; }
            @media screen and (max-width: 640px) {
              article.asset-form > section form .fieldset.two-up input {
                width: 100%;
                margin-bottom: 1rem; } }
          @media screen and (max-width: 640px) {
            article.asset-form > section form .fieldset.two-up {
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
              margin-bottom: 0; } }
        article.asset-form > section form .fieldset.submit .button {
          display: block;
          width: auto;
          margin: 0 auto;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          padding: 1rem 5.25rem;
          background: #f8e71d;
          text-decoration: none;
          color: black;
          text-transform: uppercase;
          font-size: 0.9rem;
          letter-spacing: 0.05rem;
          border: 0;
          margin-top: 2rem;
          cursor: pointer;
          transition: all 0.3s ease; }
          article.asset-form > section form .fieldset.submit .button:hover {
            background: #b8ac1e;
            color: white;
            transition: all 0.3s ease; }
        article.asset-form > section form .fieldset input {
          border-color: #fff;
          color: white;
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 1.2rem;
          background: transparent; }
          article.asset-form > section form .fieldset input:-ms-input-placeholder {
            color: white; }
          article.asset-form > section form .fieldset input::placeholder {
            color: white; }
      @media screen and (max-width: 640px) {
        article.asset-form > section form {
          width: 80%; } }
  @media screen and (max-width: 640px) {
    article.asset-form {
      padding: 0.75rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    article.asset-form {
      padding: 2rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    article.asset-form {
      padding: 5rem calc(50vw - 720rem) 3rem; } }
  @media screen and (min-width: 902px) and (max-width: 1110px) {
    article.asset-form {
      padding: 2rem 1rem; } }

section.form .form {
  padding-bottom: 8rem;
  padding-top: 1rem; }
  section.form .form .buttonWrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    padding-bottom: 1.5rem; }
    section.form .form .buttonWrap .button {
      background: #7CDEDC url("//hm.proofpoint.com/images/20055_button-arrow.png") no-repeat 90% center;
      color: #414141;
      box-shadow: 0px 2px 20px 2px rgba(65, 65, 65, 0.2);
      padding: 0.7rem 1rem;
      min-width: 120px;
      width: 180px;
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.3s ease;
      margin-left: 1rem;
      margin-top: 1rem; }
      section.form .form .buttonWrap .button:hover {
        background-color: #72B7B6;
        transition: all 0.3s ease; }
      section.form .form .buttonWrap .button.prev {
        background: #7CDEDC url("//hm.proofpoint.com/images/20055_button-arrow-prev.png") no-repeat 10% center;
        text-align: right;
        margin-left: 0; }
        section.form .form .buttonWrap .button.prev:hover {
          background-color: #72B7B6;
          transition: all 0.3s ease; }
  section.form .form #confirmform {
    text-align: center; }
  section.form .form form {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 5%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    section.form .form form.mktoForm .mktoFormRow {
      width: 45%; }
      section.form .form form.mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap input, section.form .form form.mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap select {
        background-color: white;
        box-shadow: none; }
      section.form .form form.mktoForm .mktoFormRow .mktoFieldDescriptor .mktoFieldWrap input:placeholder {
        color: black; }
      @media screen and (max-width: 655px) {
        section.form .form form.mktoForm .mktoFormRow {
          width: 100%; } }
      section.form .form form.mktoForm .mktoFormRow.radioButtons {
        width: 100%; }
        section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: start;
              justify-content: flex-start;
          width: 250px !important; }
          section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList input[type="radio"] {
            width: 25px !important;
            height: 25px;
            padding: 0;
            appearance: unset;
            background: none;
            border-radius: 20px;
            box-shadow: none; }
            section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList input[type="radio"]:checked {
              background: #7CDEDC; }
              section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList input[type="radio"]:checked + label:before {
                background: #7CDEDC; }
            section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList input[type="radio"] + label {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
                  align-items: center;
              padding-left: 1rem;
              margin-left: 0;
              margin-right: 3rem; }
              section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList input[type="radio"] + label:before {
                box-shadow: none;
                height: 25px !important;
                width: 25px !important;
                display: block;
                margin-left: -2rem; }
          section.form .form form.mktoForm .mktoFormRow.radioButtons .mktoRadioList label {
            margin-left: 0.5rem;
            margin-right: 2rem; }
    section.form .form form.mktoForm .mktoButtonRow .mktoButtonWrap.mktoSimple .mktoButton {
      background-image: none;
      background: #7CDEDC;
      color: #414141;
      box-shadow: 0px 2px 20px 2px rgba(65, 65, 65, 0.2);
      transition: all 0.3s ease;
      font-size: 1rem; }
      section.form .form form.mktoForm .mktoButtonRow .mktoButtonWrap.mktoSimple .mktoButton:hover {
        background-color: #72B7B6;
        transition: all 0.3s ease; }
    section.form .form form.mktoForm .mktoButtonRow .mktoButtonWrap.mktoGlow .mktoButton {
      background-image: none;
      background: #7CDEDC;
      color: #414141;
      box-shadow: 0px 2px 20px 2px rgba(65, 65, 65, 0.2);
      transition: all 0.3s ease;
      font-size: 1rem; }
      section.form .form form.mktoForm .mktoButtonRow .mktoButtonWrap.mktoGlow .mktoButton:hover {
        background-color: #72B7B6;
        transition: all 0.3s ease; }
    @media screen and (max-width: 941px) {
      section.form .form form {
        width: 100% !important; } }

.generic form .mktoButtonRow .mktoButtonWrap.mktoRound button.mktoButton {
  background: #FF6B00; }

.generic form input[type="checkbox"]:checked, .generic form select[type="checkbox"]:checked {
  background-color: #FF6B00; }

.generic form select {
  background-image: url(../images/new/arrow-down.png); }

.generic form button {
  background: #FF6B00; }

form label {
  font-size: 0.95rem; }

form .checkbox {
  display: -ms-flexbox;
  display: flex; }
  form .checkbox input[type="checkbox"] {
    width: 25px; }

form input, form select {
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  background: #F1F1F1;
  border: 1px solid #d6d6d6;
  border-radius: 0px;
  color: #595959;
  font-size: 0.8rem; }
  form input[type="checkbox"], form select[type="checkbox"] {
    padding: 0;
    width: 1rem;
    height: 1rem; }
    form input[type="checkbox"]:checked, form select[type="checkbox"]:checked {
      background-color: #E30620; }
      [data-theme="green"] form input[type="checkbox"]:checked, [data-theme="green"] form select[type="checkbox"]:checked {
        background-color: #00a499; }
  form input.mktoInvalid, form select.mktoInvalid {
    border-color: red; }

form button {
  background: #E30620;
  border-radius: 0em;
  padding: .85rem 1rem;
  color: #fff;
  font-weight: bold;
  font-size: .9375rem;
  line-height: 1.5;
  width: 100%;
  margin-top: 2rem;
  text-transform: uppercase; }

form select {
  appearance: none;
  background-color: #F1F1F1;
  background-position: 96%;
  background-repeat: no-repeat; }
  [data-theme="green"] form select {
    background-image: url("../images/green-arrow-down.png"); }

form select::-ms-expand {
  display: none; }

form .mktoButtonRow {
  width: 100%; }
  form .mktoButtonRow .mktoButtonWrap {
    margin-left: 0 !important; }
    form .mktoButtonRow .mktoButtonWrap.mktoRound button.mktoButton {
      background: #E30620; }
    form .mktoButtonRow .mktoButtonWrap .mktoButton {
      border: 0 !important;
      border-radius: 0 !important;
      padding: 0.85rem 1rem !important;
      margin-top: 1.2rem !important;
      background: #E30620; }

form .mktoError {
  display: none !important; }

form .mktoFormCol {
  width: 100%;
  margin-bottom: 0.6rem !important; }
  form .mktoFormCol .mktoOffset {
    display: none; }
  form .mktoFormCol .mktoFieldWrap {
    width: 100%; }
    form .mktoFormCol .mktoFieldWrap .mktoHtmlText {
      width: 100% !important;
      font-size: 0.7rem; }

form .mktoFieldDescriptor {
  padding: 0.8rem 0;
  width: 100%; }
  form .mktoFieldDescriptor .mktoCheckboxList {
    padding: 0;
    width: 100% !important;
    display: -ms-flexbox;
    display: flex; }
    form .mktoFieldDescriptor .mktoCheckboxList label {
      margin-left: 0; }
  form .mktoFieldDescriptor .mktoOffset {
    display: none; }
  form .mktoFieldDescriptor .mktoFieldWrap {
    width: 100%; }
    form .mktoFieldDescriptor .mktoFieldWrap .mktoGutter {
      display: none; }
    form .mktoFieldDescriptor .mktoFieldWrap .mktoLabel {
      font-weight: 300 !important;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-size: 0.8rem;
      padding-bottom: 0.4rem;
      width: auto !important; }
    form .mktoFieldDescriptor .mktoFieldWrap input {
      width: 100% !important;
      padding: 1rem 0 1rem 1rem;
      font-size: .8rem; }
      form .mktoFieldDescriptor .mktoFieldWrap input[type=checkbox] {
        width: 28px !important;
        height: 1rem;
        padding: 0;
        font-size: 1rem; }
    form .mktoFieldDescriptor .mktoFieldWrap select {
      width: 100% !important;
      padding: 1rem 0 1rem 1rem;
      font-size: .8rem; }

.slick-slide {
  position: relative; }

.insightsOverlay {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 200; }
  .insightsOverlay.active {
    display: block;
    padding-bottom: 2rem; }

.slide1:nth-child(3) section.slide1 .headerQuestions .progress .progressInner {
  width: 40%; }

.slide1:nth-child(4) section.slide1 .headerQuestions .progress .progressInner {
  width: 60%; }

.slide1:nth-child(5) section.slide1 .headerQuestions .progress .progressInner {
  width: 80%; }

@media screen and (max-width: 375px) {
  .slide1:nth-child(6) .title-question-block .question p {
    font-size: 0.8rem !important;
    width: 85%; } }

.slide1:nth-child(6) section.slide1 .headerQuestions .progress .progressInner {
  width: 20%; }

.slide1:nth-child(7) section.slide1 .headerQuestions .progress .progressInner {
  width: 40%; }

.slide1:nth-child(8) section.slide1 .headerQuestions .progress .progressInner {
  width: 60%; }

.slide1:nth-child(9) section.slide1 .headerQuestions .progress .progressInner {
  width: 80%; }

.slide1.form .headerQuestions .progress .progressInner {
  width: 100%; }
  .slide1.form .headerQuestions .progress .progressInner .tick {
    right: 0px; }

section.slide1 {
  overflow: hidden;
  position: relative;
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  section.slide1.form {
    display: block; }
  section.slide1 .quizBox {
    width: 100%;
    background: white;
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    margin-top: 2rem;
    position: relative; }
    @media screen and (min-width: 1500px) {
      section.slide1 .quizBox {
        max-width: 1400px; } }
    section.slide1 .quizBox .leftCornerTop {
      position: absolute;
      left: 0;
      top: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-top-left.jpg") no-repeat left top;
      width: 2.7rem;
      height: 2.5rem;
      z-index: 2; }
    section.slide1 .quizBox .rightMiddle {
      position: absolute;
      right: 0;
      top: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/quiz-right-middle.jpg") repeat-y right bottom;
      width: 1.7rem;
      height: 100%; }
    section.slide1 .quizBox .bottomMiddle {
      position: absolute;
      left: 0;
      bottom: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/quiz-bottom-middle.jpg") repeat-x center bottom;
      width: 100%;
      height: 2.5rem; }
    section.slide1 .quizBox .rightCornerTop {
      position: absolute;
      right: 0;
      top: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-top-right.jpg") no-repeat right top;
      width: 2.7rem;
      height: 2.5rem;
      z-index: 2; }
    section.slide1 .quizBox .leftCornerbottom {
      position: absolute;
      left: 0;
      bottom: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-bottom-left.jpg") no-repeat left bottom;
      width: 2.4rem;
      height: 2.7rem;
      z-index: 2; }
    section.slide1 .quizBox .rightCornerbottom {
      position: absolute;
      right: 0;
      bottom: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-bottom-right.jpg") no-repeat right bottom;
      width: 2.4rem;
      height: 2.5rem;
      z-index: 2; }
  section.slide1 .headerQuestions {
    width: 100%;
    padding: 3rem 2rem 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center; }
    section.slide1 .headerQuestions .headerTitle {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%; }
      section.slide1 .headerQuestions .headerTitle h1 {
        color: #009ada;
        font-size: 3.4rem;
        font-family: 'Tungsten'; }
      section.slide1 .headerQuestions .headerTitle .progressSection {
        display: -ms-flexbox;
        display: flex;
        padding-top: 1rem;
        -ms-flex-line-pack: center;
            align-content: center;
        -ms-flex-align: center;
            align-items: center; }
        section.slide1 .headerQuestions .headerTitle .progressSection > p {
          font-size: 1.8rem;
          font-family: 'Tungsten'; }
          @media screen and (max-width: 445px) {
            section.slide1 .headerQuestions .headerTitle .progressSection > p {
              font-size: 1.2rem; } }
        section.slide1 .headerQuestions .headerTitle .progressSection img {
          padding-left: 1rem; }
          @media screen and (max-width: 695px) {
            section.slide1 .headerQuestions .headerTitle .progressSection img {
              padding-left: 0;
              margin-top: 1rem; } }
        @media screen and (max-width: 695px) {
          section.slide1 .headerQuestions .headerTitle .progressSection {
            -ms-flex-direction: column;
                flex-direction: column;
            -ms-flex-pack: start;
                justify-content: flex-start;
            -ms-flex-line-pack: start;
                align-content: flex-start;
            -ms-flex-align: start;
                align-items: flex-start; } }
    section.slide1 .headerQuestions .headerImage {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: end;
          justify-content: flex-end; }
      section.slide1 .headerQuestions .headerImage img {
        margin-right: 5rem;
        width: 45%; }
  section.slide1 .bottom {
    width: 100%;
    padding: 0 2rem;
    padding-bottom: 2rem; }
    @media screen and (max-width: 1010px) {
      section.slide1 .bottom {
        padding: 2rem 1.5rem 2rem 1.5rem; } }
    @media screen and (max-width: 768px) {
      section.slide1 .bottom {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        padding-top: 1rem;
        -ms-flex-pack: end;
            justify-content: flex-end; } }
    @media screen and (max-width: 470px) {
      section.slide1 .bottom {
        padding: 1rem 1rem 2rem; } }
    section.slide1 .bottom .answerWrap {
      width: 85%;
      -ms-flex: 1 1 85%;
          flex: 1 1 85%;
      padding-bottom: 2rem; }
      @media screen and (max-width: 768px) {
        section.slide1 .bottom .answerWrap {
          -ms-flex-order: 2;
              order: 2;
          width: 100%;
          -ms-flex: 1 1 100%;
              flex: 1 1 100%;
          margin-top: 0rem;
          z-index: 2; } }
      @media screen and (max-width: 630px) {
        section.slide1 .bottom .answerWrap {
          margin-top: 0rem; } }
      section.slide1 .bottom .answerWrap.active .answers label span {
        color: #ccc; }
    section.slide1 .bottom .bottomInnerWrap {
      width: 98%; }
      section.slide1 .bottom .bottomInnerWrap .answers {
        position: relative; }
        section.slide1 .bottom .bottomInnerWrap .answers p.error {
          display: none;
          padding: 0.5rem 1rem;
          border-radius: 3px;
          background: #f8c3c3;
          border: 1px solid #c00;
          margin-left: 1rem;
          font-family: 'Open Sans', sans-serif;
          font-weight: 300;
          font-size: 0.9rem;
          transition: all 0.8s ease; }
          section.slide1 .bottom .bottomInnerWrap .answers p.error.active {
            display: block;
            padding: 0.5rem 1rem;
            border-radius: 3px;
            background: #f8c3c3;
            border: 1px solid #c00;
            margin-left: 1rem;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 0.9rem;
            transition: all 0.8s ease; }
        section.slide1 .bottom .bottomInnerWrap .answers p {
          color: #414141;
          font-size: 0.95rem;
          padding-bottom: 1.2rem; }
        section.slide1 .bottom .bottomInnerWrap .answers .fieldset {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          padding: 0.7rem 1rem;
          transition: all 0.3s ease; }
          section.slide1 .bottom .bottomInnerWrap .answers .fieldset input {
            background: white;
            border-radius: 3px; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"], section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="radio"] {
              width: 2.1rem;
              height: 1.6rem;
              margin-right: 1.5rem;
              margin-right: 1.5rem; }
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"]:checked, section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="radio"]:checked {
                background: #009ada url("//http://hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"]:checked ~ .radioBtn, section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="radio"]:checked ~ .radioBtn {
                  background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"].checked, section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="radio"].checked {
                background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"].checked ~ .radioBtn, section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="radio"].checked ~ .radioBtn {
                  background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset input.error {
              display: block;
              border: 1px solid #c00; }
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset input.error ~ .radioBtn {
                border: 1px solid #c00; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="radio"], section.slide1 .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0; }
          section.slide1 .bottom .bottomInnerWrap .answers .fieldset label {
            color: #414141;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            width: 100%; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset label > div {
              display: block;
              position: relative;
              width: 2rem;
              height: 2rem;
              margin-right: 1.5rem; }
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset label > div .radioBtn {
                width: 2rem;
                height: 2rem;
                box-shadow: 0px 2px 20px 2px rgba(65, 65, 65, 0.2);
                background: white;
                display: block; }
              @media screen and (max-width: 1110px) {
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset label > div {
                  width: 10%;
                  margin-right: 1.5%; } }
              @media screen and (max-width: 380px) {
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset label > div {
                  width: 10%;
                  margin-right: 1rem; } }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset label span {
              width: 80%; }
              @media screen and (max-width: 1110px) {
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset label span {
                  width: 88.5%;
                  font-size: 0.85rem; } }
            @media screen and (max-width: 1110px) {
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset label {
                -ms-flex-pack: justify;
                    justify-content: space-between;
                width: 100%; } }
          section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insightswrap {
            display: none;
            position: relative; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insightswrap .leftCornerTop {
              position: absolute;
              left: 0;
              top: 0;
              -ms-transform: translateY(0%);
                  transform: translateY(0%);
              background: #fff url("../images/answer-top-left.jpg") no-repeat left top;
              width: 2.7rem;
              height: 2.5rem;
              z-index: 2; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insightswrap .rightCornerTop {
              position: absolute;
              right: 0;
              top: 0;
              -ms-transform: translateY(0%);
                  transform: translateY(0%);
              background: #fff url("../images/answer-top-right.jpg") no-repeat right top;
              width: 2.7rem;
              height: 2.5rem;
              z-index: 2; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insightswrap .leftCornerbottom {
              position: absolute;
              left: 0;
              bottom: 0;
              -ms-transform: translateY(0%);
                  transform: translateY(0%);
              background: #fff url("../images/answer-bottom-left.jpg") no-repeat left bottom;
              width: 2.4rem;
              height: 2.7rem;
              z-index: 2; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insightswrap .rightCornerbottom {
              position: absolute;
              right: 0;
              bottom: 0;
              -ms-transform: translateY(0%);
                  transform: translateY(0%);
              background: #fff url("../images/quiz-bottom-right.jpg") no-repeat right bottom;
              width: 2.4rem;
              height: 2.5rem;
              z-index: 2; }
          section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insight {
            display: none;
            position: absolute;
            left: 100%;
            top: 0%;
            height: 100%;
            -ms-flex-align: center;
                align-items: center;
            min-width: 350px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-style: italic;
            font-size: 1.6rem;
            color: #414141; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insight.active {
              display: -ms-flexbox;
              display: flex; }
              @media screen and (max-width: 655px) {
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insight.active {
                  display: none; } }
            @media screen and (max-width: 1300px) {
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insight {
                left: 93%;
                top: 25%;
                font-size: 1.3rem; } }
            @media screen and (max-width: 1110px) {
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insight {
                left: 120%;
                top: 25%;
                -ms-flex-line-pack: start;
                    align-content: flex-start; } }
            @media screen and (max-width: 768px) {
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset .insight {
                min-width: 290px;
                top: 10%; } }
          section.slide1 .bottom .bottomInnerWrap .answers .fieldset.active {
            background: #f5f5f5; }
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset.active .insight {
              display: -ms-flexbox;
              display: flex;
              margin-left: -12%;
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap; }
              section.slide1 .bottom .bottomInnerWrap .answers .fieldset.active .insight .refs {
                position: absolute;
                bottom: -20%;
                width: 100%;
                font-size: 0.8rem;
                font-style: normal; }
                section.slide1 .bottom .bottomInnerWrap .answers .fieldset.active .insight .refs a {
                  color: #414141; }
                @media screen and (max-width: 1110px) {
                  section.slide1 .bottom .bottomInnerWrap .answers .fieldset.active .insight .refs {
                    bottom: 0; } }
          section.slide1 .bottom .bottomInnerWrap .answers .fieldset:hover {
            background: #f5f5f5;
            transition: all 0.3s ease; }
          @media screen and (max-width: 470px) {
            section.slide1 .bottom .bottomInnerWrap .answers .fieldset {
              padding: .7rem 0; } }
      section.slide1 .bottom .bottomInnerWrap .insights .insight {
        display: none; }
      @media screen and (max-width: 1110px) {
        section.slide1 .bottom .bottomInnerWrap {
          width: 55%; } }
      @media screen and (max-width: 655px) {
        section.slide1 .bottom .bottomInnerWrap {
          width: 95%; } }
    @media screen and (max-width: 630px) {
      section.slide1 .bottom .insightsOverlay {
        width: 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        padding-right: 0; } }
    section.slide1 .bottom .insightsOverlay .insightNext {
      display: none; }
    section.slide1 .bottom .insightsOverlay .insightsOpen {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      color: #414141;
      text-decoration: none;
      padding: 0.2rem;
      border-radius: 3rem;
      transition: all 0.3s ease;
      outline: none;
      border: 0;
      font-size: 0.9rem;
      -ms-flex-pack: end;
          justify-content: flex-end;
      padding-right: 1rem;
      width: 166px; }
      section.slide1 .bottom .insightsOverlay .insightsOpen:before {
        content: '';
        display: block;
        background-size: 20%;
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        margin-right: 0.7rem;
        box-shadow: 0 2px 20px 2px rgba(65, 65, 65, 0.2);
        -ms-flex: none;
            flex: none; }
      section.slide1 .bottom .insightsOverlay .insightsOpen:hover {
        background: #efefef;
        transition: all 0.3s ease; }
      @media screen and (max-width: 1035px) {
        section.slide1 .bottom .insightsOverlay .insightsOpen {
          -ms-transform: translateX(-2rem);
              transform: translateX(-2rem); } }
      @media screen and (max-width: 767px) {
        section.slide1 .bottom .insightsOverlay .insightsOpen {
          -ms-transform: translateX(0rem);
              transform: translateX(0rem); }
          section.slide1 .bottom .insightsOverlay .insightsOpen:hover {
            background: transparent;
            transition: all 0.3s ease; } }
      @media screen and (max-width: 655px) {
        section.slide1 .bottom .insightsOverlay .insightsOpen {
          -ms-transform: translateX(-1rem);
              transform: translateX(-1rem); } }
    section.slide1 .bottom .insightsOverlay.active .insightswrap {
      padding: 1rem 0;
      width: 90%;
      position: relative;
      background: #e9e9e9; }
      section.slide1 .bottom .insightsOverlay.active .insightswrap .insight1 {
        height: auto;
        padding: 1rem;
        background-repeat: no-repeat;
        background-size: 100% 97%;
        position: relative;
        z-index: 3; }
      section.slide1 .bottom .insightsOverlay.active .insightswrap .leftCornerTop {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/answer-top-left.jpg") no-repeat left top;
        width: 1.8rem;
        height: 1.8rem;
        z-index: 2; }
      section.slide1 .bottom .insightsOverlay.active .insightswrap .rightCornerTop {
        position: absolute;
        right: 0;
        top: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/answer-top-right.jpg") no-repeat right top;
        width: 1.8rem;
        height: 1.8rem;
        z-index: 2; }
      section.slide1 .bottom .insightsOverlay.active .insightswrap .leftCornerbottom {
        position: absolute;
        left: 0;
        bottom: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/answer-bottom-left.jpg") no-repeat left bottom;
        width: 1.8rem;
        height: 1.8rem;
        z-index: 2; }
      section.slide1 .bottom .insightsOverlay.active .insightswrap .rightCornerbottom {
        position: absolute;
        right: 0;
        bottom: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/answer-bottom-right.jpg") no-repeat right bottom;
        width: 1.8rem;
        height: 1.8rem;
        z-index: 2; }
    section.slide1 .bottom .insightsOverlay.active .insightNext {
      display: block;
      outline: none;
      padding: 1rem;
      background-image: url("../images/buttonBG.png");
      background-size: cover;
      background-color: transparent;
      color: #fff;
      padding: .7rem 1rem;
      min-width: 120px;
      width: 160px;
      display: block;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      transition: all .3s ease;
      margin-left: 1rem;
      padding: 0.8rem 1rem;
      z-index: 2;
      position: relative;
      margin-top: 1.5rem; }
      section.slide1 .bottom .insightsOverlay.active .insightNext:hover {
        color: #E9E9E9;
        transition: all 0.3s ease; }
      @media screen and (max-width: 990px) {
        section.slide1 .bottom .insightsOverlay.active .insightNext {
          right: unset;
          left: 0%;
          top: 22px;
          margin-left: 0; } }
      @media screen and (max-width: 720px) {
        section.slide1 .bottom .insightsOverlay.active .insightNext {
          left: 4%; } }
      @media screen and (max-width: 336px) {
        section.slide1 .bottom .insightsOverlay.active .insightNext {
          left: 0; } }
    section.slide1 .bottom .insightsOverlay.active .insightsOpen {
      position: absolute;
      right: 20px;
      top: 10px;
      color: #414141;
      padding-right: 1rem;
      background: #efefef; }
      @media screen and (max-width: 990px) {
        section.slide1 .bottom .insightsOverlay.active .insightsOpen {
          right: 14%;
          top: 15px; } }
      @media screen and (max-width: 720px) {
        section.slide1 .bottom .insightsOverlay.active .insightsOpen {
          right: 8%; } }
      @media screen and (max-width: 430px) {
        section.slide1 .bottom .insightsOverlay.active .insightsOpen {
          right: 2%; } }
      section.slide1 .bottom .insightsOverlay.active .insightsOpen:hover {
        color: #000;
        background: #ccc; }
      section.slide1 .bottom .insightsOverlay.active .insightsOpen:before {
        content: '';
        display: block;
        background-size: 30%;
        width: 3rem;
        height: 2.85rem;
        border-radius: 3rem;
        margin-right: 0.7rem;
        box-shadow: 0 2px 20px 2px rgba(65, 65, 65, 0.2);
        -ms-flex: none;
            flex: none; }
      @media screen and (max-width: 767px) {
        section.slide1 .bottom .insightsOverlay.active .insightsOpen:hover {
          background: #efefef;
          transition: all 0.3s ease; } }
    @media screen and (max-width: 1000px) {
      section.slide1 .bottom .insightsOverlay.active {
        padding-top: 2rem; } }
    section.slide1 .bottom .buttonWrap {
      display: -ms-flexbox;
      display: flex;
      z-index: 2;
      position: relative;
      padding-top: 2rem; }
      section.slide1 .bottom .buttonWrap .button {
        display: block;
        outline: none;
        padding: 1rem;
        background-image: url("../images/buttonBG.png");
        background-size: cover;
        background-color: transparent;
        color: #fff;
        padding: .7rem 1rem;
        min-width: 120px;
        width: 160px;
        display: block;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        transition: all .3s ease;
        margin-left: 1rem;
        padding: .8rem 1rem; }
        section.slide1 .bottom .buttonWrap .button:hover {
          color: #E9E9E9;
          transition: all 0.3s ease; }
        @media screen and (max-width: 330px) {
          section.slide1 .bottom .buttonWrap .button {
            font-size: 0.9rem; } }
      section.slide1 .bottom .buttonWrap.active {
        display: none; }
    section.slide1 .bottom .notes {
      display: none;
      padding-top: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem; }
      section.slide1 .bottom .notes p {
        color: #414141;
        font-size: 0.8rem;
        opacity: 0.8; }
      section.slide1 .bottom .notes ul {
        margin-left: 1.25rem;
        padding-top: 2rem; }
        section.slide1 .bottom .notes ul li {
          padding-left: 1rem;
          color: #414141;
          font-size: 0.8rem;
          opacity: 0.8;
          padding-bottom: 0.7rem; }
    section.slide1 .bottom .references {
      padding-top: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #414141;
      opacity: 0.8;
      font-size: 0.8rem; }
      @media screen and (max-width: 470px) {
        section.slide1 .bottom .references {
          padding-left: 0; } }
  section.slide1 .overlay {
    position: relative;
    width: 150%;
    margin-left: -25%;
    z-index: 2; }
    @media screen and (min-width: 1550px) {
      section.slide1 .overlay {
        width: 130%;
        margin-left: -15%; } }
    @media screen and (min-width: 1750px) {
      section.slide1 .overlay {
        width: 120%;
        margin-left: -10%; } }
    @media screen and (min-width: 1950px) {
      section.slide1 .overlay {
        width: 110%;
        margin-left: -5%; } }
    @media screen and (max-width: 655px) {
      section.slide1 .overlay {
        width: 350%;
        margin-left: -90%; } }
    @media screen and (max-width: 480px) {
      section.slide1 .overlay {
        width: 500%;
        margin-left: -170%; } }
  section.slide1 .title-question-block {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%; }
    section.slide1 .title-question-block .question {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding-left: 8rem;
      padding-right: 2rem;
      padding-top: 3.8rem; }
      section.slide1 .title-question-block .question h1 {
        font-weight: 300;
        font-size: 1.6rem;
        color: white;
        width: 50%; }
        section.slide1 .title-question-block .question h1 span {
          font-size: 1.25rem;
          display: inline-block;
          margin-bottom: 1rem; }
        @media screen and (max-width: 655px) {
          section.slide1 .title-question-block .question h1 {
            width: 95%; } }
      section.slide1 .title-question-block .question p {
        font-size: 1rem;
        color: white;
        width: 50%; }
        @media screen and (max-width: 900px) {
          section.slide1 .title-question-block .question p {
            font-size: 0.8rem; } }
        @media screen and (max-width: 700px) {
          section.slide1 .title-question-block .question p {
            font-size: 0.7rem; } }
        @media screen and (max-width: 655px) {
          section.slide1 .title-question-block .question p {
            font-size: 0.9rem;
            width: 75%; } }
        @media screen and (max-width: 375px) {
          section.slide1 .title-question-block .question p {
            font-size: 1rem;
            width: 85%; } }
      @media screen and (max-width: 1255px) {
        section.slide1 .title-question-block .question {
          padding-top: 2.8rem; }
          section.slide1 .title-question-block .question h1 {
            font-size: 1.3rem; } }
      @media screen and (max-width: 1110px) {
        section.slide1 .title-question-block .question {
          padding-left: 3rem;
          padding-top: 2rem; }
          section.slide1 .title-question-block .question h1 {
            font-size: 1.3rem; }
            section.slide1 .title-question-block .question h1 span {
              font-size: 1rem;
              margin-bottom: 0.5rem; } }
      @media screen and (max-width: 980px) {
        section.slide1 .title-question-block .question h1 {
          width: 60%; } }
      @media screen and (max-width: 820px) {
        section.slide1 .title-question-block .question {
          padding-left: 1rem;
          padding-top: 1.5rem; }
          section.slide1 .title-question-block .question h1 {
            font-size: 1.1rem;
            width: 55%; }
            section.slide1 .title-question-block .question h1 span {
              font-size: 0.9rem;
              margin-bottom: 0.5rem; } }
      @media screen and (max-width: 655px) {
        section.slide1 .title-question-block .question {
          padding-right: 1rem;
          padding-top: 4rem; }
          section.slide1 .title-question-block .question h1 {
            font-size: 1.1rem;
            width: 75%; }
            section.slide1 .title-question-block .question h1 span {
              font-size: 0.9rem;
              margin-bottom: 0.5rem; } }
      @media screen and (max-width: 330px) {
        section.slide1 .title-question-block .question {
          padding-top: 2rem; } }

section.thank_you hr {
  margin-top: 8rem; }

section.thank_you .split {
  display: -ms-flexbox;
  display: flex; }
  section.thank_you .split .leftCopy {
    -ms-flex: 0 0 55%;
        flex: 0 0 55%; }
    section.thank_you .split .leftCopy h1 {
      color: #009ada;
      font-size: 3.9rem;
      font-family: 'Tungsten';
      padding-right: 10rem; }
    section.thank_you .split .leftCopy p {
      color: #5F6369;
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-top: 2rem;
      padding-right: 10rem;
      padding-bottom: 3rem; }
    section.thank_you .split .leftCopy a {
      background-image: url("../images/btnBg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #009ada;
      padding: 1rem 4rem;
      text-decoration: none;
      font-weight: 600;
      margin-top: 2rem;
      display: inline-block; }
  section.thank_you .split .rightCallout {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%; }
    section.thank_you .split .rightCallout .content {
      padding: 1.5rem;
      padding-right: 0;
      position: relative;
      background: #E9E9E9; }
      section.thank_you .split .rightCallout .content .leftCornerTop {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/top-left.jpg") no-repeat left top;
        width: 1.7rem;
        height: 1.7rem;
        z-index: 2; }
      section.thank_you .split .rightCallout .content .topMiddle {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #E9E9E9;
        width: 100%;
        height: 2rem;
        background-size: 100%; }
      section.thank_you .split .rightCallout .content .leftMiddle {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #fff url("../images/calloutBg.svg") no-repeat left center;
        width: 10%;
        height: 100%;
        background-size: auto 100%; }
      section.thank_you .split .rightCallout .content .bottomMiddle {
        position: absolute;
        left: 0;
        bottom: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #fff url("../images/bottom-middle.jpg") repeat-x center bottom;
        width: 100%;
        height: 2rem; }
      section.thank_you .split .rightCallout .content .rightMiddle {
        position: absolute;
        right: 0;
        top: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #fff url("../images/right-middle.jpg") repeat-y right bottom;
        width: 1.7rem;
        height: 100%; }
      section.thank_you .split .rightCallout .content .rightCornerTop {
        position: absolute;
        right: 0;
        top: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/top-right.jpg") no-repeat right top;
        width: 1.7rem;
        height: 1.7rem;
        z-index: 2; }
      section.thank_you .split .rightCallout .content .leftCornerbottom {
        position: absolute;
        left: 0;
        bottom: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/bottom-left.jpg") no-repeat left bottom;
        width: 1.7rem;
        height: 2rem;
        z-index: 2; }
      section.thank_you .split .rightCallout .content .rightCornerbottom {
        position: absolute;
        right: 0;
        bottom: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/bottom-right.jpg") no-repeat right bottom;
        width: 1.7rem;
        height: 2rem;
        z-index: 2; }
      section.thank_you .split .rightCallout .content h3 {
        font-size: 2.3rem;
        padding-right: 2rem;
        z-index: 3;
        position: relative;
        font-family: 'Tungsten'; }
        @media screen and (max-width: 1000px) {
          section.thank_you .split .rightCallout .content h3 {
            padding-right: 3rem; } }
      section.thank_you .split .rightCallout .content p {
        line-height: 1.4rem;
        width: 70%;
        padding-top: 1.5rem;
        z-index: 3;
        position: relative; }
      section.thank_you .split .rightCallout .content .formWrap {
        width: 90%;
        padding-top: 1rem; }
        section.thank_you .split .rightCallout .content .formWrap form.mktoForm {
          width: 100% !important; }
          section.thank_you .split .rightCallout .content .formWrap form.mktoForm #addl_info {
            text-align: left;
            width: 70%; }
            section.thank_you .split .rightCallout .content .formWrap form.mktoForm #addl_info a {
              color: #009ada; }
          section.thank_you .split .rightCallout .content .formWrap form.mktoForm input[type=text] {
            border: 0;
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
            padding: 1.8rem 1rem; }
          section.thank_you .split .rightCallout .content .formWrap form.mktoForm input[type=email] {
            border: 0;
            background: white;
            border-radius: 0.4rem;
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
            padding: 1.8rem 1rem; }
          section.thank_you .split .rightCallout .content .formWrap form.mktoForm input[type=tel] {
            border: 0;
            background: white;
            border-radius: 0.4rem;
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
            padding: 1.8rem 1rem; }
          section.thank_you .split .rightCallout .content .formWrap form.mktoForm select {
            border: 0;
            background: white;
            border-radius: 0.4rem;
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
            padding: 0rem 1rem;
            color: #66656c;
            appearance: none;
            height: 55px;
            outline: none; }
          section.thank_you .split .rightCallout .content .formWrap form.mktoForm button[type=submit] {
            display: block;
            outline: none;
            padding: 1rem;
            background-image: url("../images/buttonBG.png");
            background-size: cover;
            background-color: transparent;
            color: #fff;
            padding: .7rem 1rem;
            min-width: 120px;
            width: 160px;
            display: block;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            transition: all .3s ease;
            margin-left: 1rem;
            margin-top: 0rem !important;
            margin-bottom: 2rem !important;
            background-image: url("../images/buttonBG.png") !important;
            font-family: 'Tungsten';
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 175px;
            padding: .25rem .5rem;
            font-size: 1.8rem !important; }
            section.thank_you .split .rightCallout .content .formWrap form.mktoForm button[type=submit]:hover {
              color: #E9E9E9;
              transition: all 0.3s ease; }
            @media screen and (max-width: 640px) {
              section.thank_you .split .rightCallout .content .formWrap form.mktoForm button[type=submit] {
                margin-left: 0;
                margin-top: 1.5rem; } }
        section.thank_you .split .rightCallout .content .formWrap input[type=text] {
          background: #fff;
          margin-bottom: 1rem;
          border-radius: .4rem; }
        section.thank_you .split .rightCallout .content .formWrap .bottom {
          display: -ms-flexbox;
          display: flex;
          padding-bottom: 2rem; }
          section.thank_you .split .rightCallout .content .formWrap .bottom .left p {
            color: #878A8F;
            font-size: .8rem;
            width: 90%; }
          section.thank_you .split .rightCallout .content .formWrap .bottom .left .checkbox label {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            font-size: 1.2rem; }
          section.thank_you .split .rightCallout .content .formWrap .bottom .left .checkbox input[type=checkbox] {
            background: #fff;
            margin-right: 1rem;
            width: 2rem;
            height: 2rem; }
          section.thank_you .split .rightCallout .content .formWrap .bottom .right button {
            margin-top: .25rem;
            display: block;
            outline: none;
            padding: 1rem;
            background-image: url("../images/buttonBG.png");
            background-size: cover;
            background-color: transparent;
            color: #fff;
            padding: .7rem 1rem;
            min-width: 120px;
            width: 160px;
            display: block;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            transition: all .3s ease;
            margin-left: 1rem;
            font-family: 'Tungsten';
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 175px;
            padding: .25rem .5rem;
            font-size: 2rem; }
            section.thank_you .split .rightCallout .content .formWrap .bottom .right button:hover {
              color: #E9E9E9;
              transition: all 0.3s ease; }
            @media screen and (max-width: 640px) {
              section.thank_you .split .rightCallout .content .formWrap .bottom .right button {
                margin-left: 0;
                margin-top: 1.5rem; } }
          @media screen and (max-width: 640px) {
            section.thank_you .split .rightCallout .content .formWrap .bottom {
              -ms-flex-direction: column;
                  flex-direction: column; } }
    section.thank_you .split .rightCallout .noCalloutCopy {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      padding-top: 2rem; }
      section.thank_you .split .rightCallout .noCalloutCopy a {
        color: #009ada;
        text-decoration: none; }

@media screen and (max-width: 640px) {
  section.thank_you {
    padding: 2rem 0.75rem; }
    section.thank_you .split {
      display: block; }
      section.thank_you .split .leftCopy {
        padding-bottom: 3rem; }
        section.thank_you .split .leftCopy h1 {
          padding-right: 2rem; }
        section.thank_you .split .leftCopy p {
          padding-right: 1rem; }
      section.thank_you .split .rightCallout {
        background-size: 100%; }
        section.thank_you .split .rightCallout .content h3 {
          padding-right: 1rem; }
        section.thank_you .split .rightCallout .content p {
          width: 100%; } }

@media screen and (min-width: 641px) and (max-width: 900px) {
  section.thank_you {
    padding: 3rem 2.5rem; }
    section.thank_you .split {
      display: block; }
      section.thank_you .split .leftCopy {
        padding-bottom: 3rem; }
        section.thank_you .split .leftCopy h1 {
          padding-right: 2rem; }
        section.thank_you .split .leftCopy p {
          padding-right: 3rem; }
      section.thank_you .split .rightCallout {
        background-size: 70%; } }

@media screen and (min-width: 901px) {
  section.thank_you {
    padding: 5rem calc(50vw - 40rem); } }

@media screen and (max-width: 1300px) {
  section.thank_you {
    padding: 5rem 2rem; } }

section.intro {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  background-size: 30%;
  min-height: 830px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -ms-flex-align: center;
      align-items: center;
  transition: all 0.3s ease;
  -ms-transform: translateX(0);
      transform: translateX(0); }
  section.intro.active {
    transition: all 0.3s ease;
    -ms-transform: translateX(-100%);
        transform: translateX(-100%); }
  section.intro .quizBox {
    width: 100%;
    background: white;
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    margin-top: 2rem;
    position: relative; }
    @media screen and (min-width: 1500px) {
      section.intro .quizBox {
        width: 1200px; } }
    section.intro .quizBox .leftCornerTop {
      position: absolute;
      left: 0;
      top: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-top-left.jpg") no-repeat left top;
      width: 2.7rem;
      height: 2.5rem;
      z-index: 2; }
    section.intro .quizBox .rightMiddle {
      position: absolute;
      right: 0;
      top: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/quiz-right-middle.jpg") repeat-y right bottom;
      width: 1.7rem;
      height: 100%; }
    section.intro .quizBox .bottomMiddle {
      position: absolute;
      left: 0;
      bottom: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/quiz-bottom-middle.jpg") repeat-x center bottom;
      width: 100%;
      height: 2.5rem; }
    section.intro .quizBox .rightCornerTop {
      position: absolute;
      right: 0;
      top: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-top-right.jpg") no-repeat right top;
      width: 2.7rem;
      height: 2.5rem;
      z-index: 2; }
    section.intro .quizBox .leftCornerbottom {
      position: absolute;
      left: 0;
      bottom: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-bottom-left.jpg") no-repeat left bottom;
      width: 2.4rem;
      height: 2.7rem;
      z-index: 2; }
    section.intro .quizBox .rightCornerbottom {
      position: absolute;
      right: 0;
      bottom: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/quiz-bottom-right.jpg") no-repeat right bottom;
      width: 2.4rem;
      height: 2.5rem;
      z-index: 2; }
  section.intro .headerQuestions {
    width: 100%;
    padding: 3rem 2rem 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center; }
    section.intro .headerQuestions .headerTitle {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%; }
      section.intro .headerQuestions .headerTitle h1 {
        color: #009ada;
        font-size: 3.4rem;
        font-family: 'Tungsten'; }
      section.intro .headerQuestions .headerTitle .progressSection {
        display: -ms-flexbox;
        display: flex;
        padding-top: 1rem;
        -ms-flex-align: center;
            align-items: center; }
        section.intro .headerQuestions .headerTitle .progressSection p {
          font-size: 1.8rem;
          font-family: 'Tungsten'; }
          @media screen and (max-width: 445px) {
            section.intro .headerQuestions .headerTitle .progressSection p {
              font-size: 1.2rem; } }
        section.intro .headerQuestions .headerTitle .progressSection img {
          padding-left: 1rem; }
          @media screen and (max-width: 695px) {
            section.intro .headerQuestions .headerTitle .progressSection img {
              padding-left: 0; } }
        @media screen and (max-width: 695px) {
          section.intro .headerQuestions .headerTitle .progressSection {
            -ms-flex-direction: column;
                flex-direction: column;
            -ms-flex-pack: start;
                justify-content: flex-start;
            -ms-flex-line-pack: start;
                align-content: flex-start;
            -ms-flex-align: start;
                align-items: flex-start; } }
      @media screen and (max-width: 445px) {
        section.intro .headerQuestions .headerTitle {
          width: 40%; } }
    section.intro .headerQuestions .headerImage {
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: end;
          justify-content: flex-end; }
      section.intro .headerQuestions .headerImage img {
        margin-right: 5rem;
        width: 45%; }
        @media screen and (max-width: 445px) {
          section.intro .headerQuestions .headerImage img {
            width: 90%;
            margin-right: 1rem; } }
      @media screen and (max-width: 445px) {
        section.intro .headerQuestions .headerImage {
          width: 60%;
          display: block; } }
  section.intro .bottom {
    width: 100%;
    padding: 0 1.5rem;
    padding-bottom: 2rem; }
    section.intro .bottom .answerWrap {
      padding-bottom: 2rem; }
    section.intro .bottom .bottomInnerWrap {
      display: block;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      section.intro .bottom .bottomInnerWrap .answers {
        display: block;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        height: auto;
        position: relative; }
        section.intro .bottom .bottomInnerWrap .answers p.error {
          display: none;
          padding: 0.5rem 1rem;
          border-radius: 3px;
          background: #f8c3c3;
          border: 1px solid #c00;
          margin-left: 1rem;
          font-family: 'Open Sans', sans-serif;
          font-weight: 300;
          font-size: 0.9rem;
          transition: all 0.8s ease; }
          section.intro .bottom .bottomInnerWrap .answers p.error.active {
            display: block;
            padding: 0.5rem 1rem;
            border-radius: 3px;
            background: #f8c3c3;
            border: 1px solid #c00;
            margin-left: 1rem;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-size: 0.9rem;
            transition: all 0.8s ease; }
        section.intro .bottom .bottomInnerWrap .answers p {
          color: #414141;
          font-size: 0.95rem;
          padding-bottom: 1.2rem; }
        section.intro .bottom .bottomInnerWrap .answers .fieldset {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          padding: 0.7rem 1rem;
          transition: all 0.3s ease; }
          section.intro .bottom .bottomInnerWrap .answers .fieldset input {
            background: white;
            border-radius: 3px; }
            section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"], section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="radio"] {
              width: 2.1rem;
              height: 1.6rem;
              margin-right: 1.5rem;
              margin-right: 1.5rem; }
              section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"]:checked, section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="radio"]:checked {
                background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
                section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"]:checked ~ .radioBtn, section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="radio"]:checked ~ .radioBtn {
                  background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
              section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"].checked, section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="radio"].checked {
                background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
                section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"].checked ~ .radioBtn, section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="radio"].checked ~ .radioBtn {
                  background: #009ada url("//hm.proofpoint.com/images/20055_checkbox-tick.png") no-repeat center center; }
            section.intro .bottom .bottomInnerWrap .answers .fieldset input.error {
              display: block;
              border: 1px solid #c00; }
              section.intro .bottom .bottomInnerWrap .answers .fieldset input.error ~ .radioBtn {
                border: 1px solid #c00; }
            section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="radio"], section.intro .bottom .bottomInnerWrap .answers .fieldset input[type="checkbox"] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0; }
          section.intro .bottom .bottomInnerWrap .answers .fieldset label {
            color: #414141;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            width: 100%; }
            section.intro .bottom .bottomInnerWrap .answers .fieldset label > div {
              display: block;
              position: relative;
              width: 2rem;
              height: 2rem;
              margin-right: 1.5rem; }
              section.intro .bottom .bottomInnerWrap .answers .fieldset label > div .radioBtn {
                width: 2rem;
                height: 2rem;
                box-shadow: 0px 2px 20px 2px rgba(65, 65, 65, 0.2);
                background: white;
                display: block; }
              @media screen and (max-width: 1110px) {
                section.intro .bottom .bottomInnerWrap .answers .fieldset label > div {
                  width: 10%;
                  margin-right: 1rem; } }
              @media screen and (max-width: 380px) {
                section.intro .bottom .bottomInnerWrap .answers .fieldset label > div {
                  width: 10%;
                  margin-right: 1rem; } }
            section.intro .bottom .bottomInnerWrap .answers .fieldset label span {
              width: 80%; }
              @media screen and (max-width: 1110px) {
                section.intro .bottom .bottomInnerWrap .answers .fieldset label span {
                  width: 88.5%;
                  font-size: 0.85rem; } }
            @media screen and (max-width: 1110px) {
              section.intro .bottom .bottomInnerWrap .answers .fieldset label {
                -ms-flex-pack: justify;
                    justify-content: space-between;
                width: 100%; } }
          section.intro .bottom .bottomInnerWrap .answers .fieldset .insightswrap {
            display: none; }
          section.intro .bottom .bottomInnerWrap .answers .fieldset .insight {
            display: none;
            position: absolute;
            left: 100%;
            top: 0%;
            height: 100%;
            -ms-flex-align: center;
                align-items: center;
            min-width: 350px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            font-style: italic;
            font-size: 1.6rem;
            color: #414141; }
            section.intro .bottom .bottomInnerWrap .answers .fieldset .insight.active {
              display: -ms-flexbox;
              display: flex; }
              @media screen and (max-width: 655px) {
                section.intro .bottom .bottomInnerWrap .answers .fieldset .insight.active {
                  display: none; } }
            @media screen and (max-width: 1300px) {
              section.intro .bottom .bottomInnerWrap .answers .fieldset .insight {
                left: 93%;
                top: 25%;
                font-size: 1.3rem; } }
            @media screen and (max-width: 1110px) {
              section.intro .bottom .bottomInnerWrap .answers .fieldset .insight {
                left: 120%;
                top: 25%;
                -ms-flex-line-pack: start;
                    align-content: flex-start; } }
            @media screen and (max-width: 768px) {
              section.intro .bottom .bottomInnerWrap .answers .fieldset .insight {
                min-width: 290px;
                top: 10%; } }
          section.intro .bottom .bottomInnerWrap .answers .fieldset.active {
            background: #f5f5f5; }
            section.intro .bottom .bottomInnerWrap .answers .fieldset.active .insight {
              display: -ms-flexbox;
              display: flex;
              margin-left: -12%;
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap; }
              section.intro .bottom .bottomInnerWrap .answers .fieldset.active .insight .refs {
                position: absolute;
                bottom: -20%;
                width: 100%;
                font-size: 0.8rem;
                font-style: normal; }
                section.intro .bottom .bottomInnerWrap .answers .fieldset.active .insight .refs a {
                  color: #414141; }
                @media screen and (max-width: 1110px) {
                  section.intro .bottom .bottomInnerWrap .answers .fieldset.active .insight .refs {
                    bottom: 0; } }
          section.intro .bottom .bottomInnerWrap .answers .fieldset:hover {
            background: #f5f5f5;
            transition: all 0.3s ease; }
          @media screen and (max-width: 470px) {
            section.intro .bottom .bottomInnerWrap .answers .fieldset {
              padding: .7rem 0.5rem; } }
    section.intro .bottom .buttonWrap {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: start;
          justify-content: flex-start;
      position: relative;
      z-index: 2; }
      section.intro .bottom .buttonWrap .button {
        display: block;
        outline: none;
        padding: 1rem;
        background-image: url("../images/buttonBG.png");
        background-size: cover;
        background-color: transparent;
        color: #fff;
        padding: .7rem 1rem;
        min-width: 120px;
        width: 160px;
        display: block;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        transition: all .3s ease;
        margin-left: 1rem;
        margin-left: 1rem;
        margin-top: 1.5rem;
        padding: .8rem 1rem; }
        section.intro .bottom .buttonWrap .button:hover {
          color: #E9E9E9;
          transition: all 0.3s ease; }
        section.intro .bottom .buttonWrap .button.prev {
          background: #7CDEDC url("//hm.proofpoint.com/images/20055_button-arrow-prev.png") no-repeat 10% center;
          text-align: right; }
          section.intro .bottom .buttonWrap .button.prev:hover {
            background-color: #72B7B6;
            transition: all 0.3s ease; }
  @media screen and (min-width: 1550px) {
    section.intro .overlay {
      width: 130%;
      margin-left: -15%; } }
  @media screen and (min-width: 1750px) {
    section.intro .overlay {
      width: 120%;
      margin-left: -10%; } }
  @media screen and (min-width: 1950px) {
    section.intro .overlay {
      width: 110%;
      margin-left: -5%; } }
  @media screen and (max-width: 1044px) {
    section.intro .overlay {
      width: 180%;
      margin-left: -35%; } }
  @media screen and (max-width: 655px) {
    section.intro .overlay {
      width: 350%;
      margin-left: -90%; } }
  @media screen and (max-width: 480px) {
    section.intro .overlay {
      width: 500%;
      margin-left: -170%; } }
  @media screen and (min-width: 1550px) {
    section.intro .bg-image {
      height: 200%;
      top: -40%; } }
  section.intro .title-question-block {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%; }
    section.intro .title-question-block .question {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding-left: 8rem;
      padding-right: 2rem;
      padding-top: 3.8rem; }
      section.intro .title-question-block .question h1 {
        font-weight: 300;
        font-size: 1.6rem;
        color: white;
        width: 50%; }
        section.intro .title-question-block .question h1 span {
          color: #98d1dc; }
        @media screen and (max-width: 375px) {
          section.intro .title-question-block .question h1 {
            width: 100%; } }
      section.intro .title-question-block .question p {
        font-weight: 300;
        color: white;
        margin-top: 1rem;
        width: 40%; }
      @media screen and (max-width: 1245px) {
        section.intro .title-question-block .question {
          padding-top: 1.8rem; } }
      @media screen and (max-width: 1105px) {
        section.intro .title-question-block .question {
          padding-top: 1rem; }
          section.intro .title-question-block .question h1 {
            font-size: 1.4rem; } }
      @media screen and (max-width: 1044px) {
        section.intro .title-question-block .question {
          padding-top: 1rem; }
          section.intro .title-question-block .question h1 {
            font-size: 1.4rem; } }
      @media screen and (max-width: 980px) {
        section.intro .title-question-block .question {
          padding-left: 1rem;
          padding-top: 1rem; }
          section.intro .title-question-block .question h1 {
            font-size: 1.2rem; }
            section.intro .title-question-block .question h1 span {
              font-size: 0.9rem;
              margin-bottom: 0.5rem; }
          section.intro .title-question-block .question p {
            font-size: 1rem;
            width: 50%; } }
      @media screen and (max-width: 820px) {
        section.intro .title-question-block .question {
          padding-left: 1rem;
          padding-top: 1rem; }
          section.intro .title-question-block .question h1 {
            font-size: 0.9rem; }
            section.intro .title-question-block .question h1 span {
              font-size: 0.9rem;
              margin-bottom: 0.5rem; }
          section.intro .title-question-block .question p {
            font-size: 0.8rem;
            width: 50%; } }
      @media screen and (max-width: 655px) {
        section.intro .title-question-block .question {
          padding-right: 1rem;
          padding-top: 4rem; }
          section.intro .title-question-block .question p {
            font-size: 0.8rem;
            width: 65%; } }
      @media screen and (max-width: 375px) {
        section.intro .title-question-block .question p {
          font-size: 0.9rem;
          width: 95%; } }
      @media screen and (max-width: 330px) {
        section.intro .title-question-block .question {
          padding-top: 2rem; } }
  section.intro:first-of-type .buttonWrap .button.prev {
    display: none; }

section.copy_image {
  background: black;
  padding: 6rem calc(50vw - 720rem);
  display: -ms-flexbox;
  display: flex; }
  section.copy_image .copy {
    width: 48%; }
    section.copy_image .copy h3 {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      font-size: 1.7rem;
      margin-bottom: 2rem; }
    section.copy_image .copy p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5rem;
      color: white;
      margin-bottom: 2.5rem; }
    section.copy_image .copy a {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      padding: 1rem 5.25rem;
      background: #f8e71d;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      font-size: 0.9rem;
      letter-spacing: 0.05rem;
      display: inline-block; }
      section.copy_image .copy a:hover {
        background: #b8ac1e; }
    @media screen and (max-width: 640px) {
      section.copy_image .copy {
        width: 100%;
        text-align: center;
        -ms-flex-order: 2;
            order: 2; } }
  section.copy_image .image {
    width: 50%;
    position: relative;
    text-align: right; }
    section.copy_image .image .imageinnerwrap {
      position: relative; }
    section.copy_image .image img {
      width: 83%;
      position: relative; }
    section.copy_image .image .play {
      height: 100%;
      width: 83%;
      position: absolute;
      top: 0;
      right: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-line-pack: center;
          align-content: center; }
      section.copy_image .image .play a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-line-pack: center;
            align-content: center;
        width: 28%; }
        section.copy_image .image .play a img, section.copy_image .image .play a svg {
          width: 90%;
          -ms-flex-item-align: center;
              -ms-grid-row-align: center;
              align-self: center; }
      @media screen and (max-width: 640px) {
        section.copy_image .image .play {
          right: 8.5%; } }
    @media screen and (max-width: 640px) {
      section.copy_image .image {
        width: 100%;
        margin-bottom: 2rem;
        text-align: center; } }
  @media screen and (max-width: 1185px) {
    section.copy_image {
      padding: 6rem 1rem; } }
  @media screen and (max-width: 640px) {
    section.copy_image {
      -ms-flex-direction: column;
          flex-direction: column; } }

section.contact {
  background: white;
  padding: 6rem calc(50vw - 720rem);
  display: -ms-flexbox;
  display: flex; }
  section.contact .title {
    width: 23%; }
    section.contact .title h2 {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.6rem; }
    @media screen and (max-width: 900px) {
      section.contact .title {
        padding-bottom: 2rem;
        text-align: center;
        width: 100%; } }
  section.contact .contact {
    width: 35%; }
    section.contact .contact h3 {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 1rem; }
    section.contact .contact p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 1.5rem; }
    section.contact .contact a {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 300;
      text-decoration: none;
      color: black;
      font-size: 1.5rem; }
    @media screen and (max-width: 900px) {
      section.contact .contact {
        padding-bottom: 2rem;
        text-align: center;
        width: 100%; } }
  @media screen and (max-width: 900px) {
    section.contact {
      padding: 6rem 1rem;
      -ms-flex-direction: column;
          flex-direction: column; } }

section.test hr {
  margin-top: 8rem; }

section.test h1 {
  color: #009ada;
  font-size: 3.9rem;
  font-family: 'Tungsten'; }
  @media screen and (min-width: 800px) {
    section.test h1 br {
      display: none; } }

section.test .split {
  display: -ms-flexbox;
  display: flex; }
  section.test .split .leftCopy {
    -ms-flex: 0 0 70%;
        flex: 0 0 70%; }
    section.test .split .leftCopy p {
      line-height: 2rem;
      padding-top: 2rem;
      padding-right: 5rem;
      padding-bottom: 1rem;
      font-size: 1.3rem;
      color: #5F6369; }
    section.test .split .leftCopy a {
      background-image: url("../images/btnBg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: white;
      padding: 1rem 4rem 0.75rem;
      text-decoration: none;
      font-weight: 600;
      margin-top: 1.25rem;
      display: inline-block;
      font-family: 'Tungsten';
      font-size: 2.5rem;
      line-height: 2rem; }
  section.test .split .rightCallout {
    -ms-flex: 0 0 30%;
        flex: 0 0 30%; }
    section.test .split .rightCallout .content {
      padding: 1.5rem;
      padding-bottom: 2.5rem;
      padding-right: 0;
      position: relative;
      background: #E9E9E9; }
      section.test .split .rightCallout .content .leftCornerTop {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/top-left.jpg") no-repeat left top;
        width: 1.7rem;
        height: 1.7rem;
        z-index: 2; }
      section.test .split .rightCallout .content .topMiddle {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #E9E9E9;
        width: 100%;
        height: 2rem;
        background-size: 100%; }
      section.test .split .rightCallout .content .leftMiddle {
        position: absolute;
        left: 0;
        top: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #fff url("../images/calloutBg.svg") no-repeat left center;
        width: 10%;
        height: 100%;
        background-size: auto 100%; }
      section.test .split .rightCallout .content .bottomMiddle {
        position: absolute;
        left: 0;
        bottom: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #fff url("../images/bottom-middle.jpg") repeat-x center bottom;
        width: 100%;
        height: 2rem; }
      section.test .split .rightCallout .content .rightMiddle {
        position: absolute;
        right: 0;
        top: 0;
        -ms-transform: translateX(0%);
            transform: translateX(0%);
        background: #fff url("../images/right-middle.jpg") repeat-y right bottom;
        width: 1.7rem;
        height: 100%; }
      section.test .split .rightCallout .content .rightCornerTop {
        position: absolute;
        right: 0;
        top: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/top-right.jpg") no-repeat right top;
        width: 1.7rem;
        height: 1.7rem;
        z-index: 2; }
      section.test .split .rightCallout .content .leftCornerbottom {
        position: absolute;
        left: 0;
        bottom: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/bottom-left.jpg") no-repeat left bottom;
        width: 1.7rem;
        height: 2rem;
        z-index: 2; }
      section.test .split .rightCallout .content .rightCornerbottom {
        position: absolute;
        right: 0;
        bottom: 0;
        -ms-transform: translateY(0%);
            transform: translateY(0%);
        background: #fff url("../images/bottom-right.jpg") no-repeat right bottom;
        width: 1.7rem;
        height: 2rem;
        z-index: 2; }
      section.test .split .rightCallout .content h3 {
        font-size: 2.7rem;
        padding-right: 6rem;
        z-index: 3;
        position: relative;
        font-family: 'Tungsten'; }
        @media screen and (max-width: 1147px) {
          section.test .split .rightCallout .content h3 {
            padding-right: 3rem; } }
        @media screen and (max-width: 1000px) {
          section.test .split .rightCallout .content h3 {
            padding-right: 3rem; } }
        @media screen and (max-width: 520px) {
          section.test .split .rightCallout .content h3 {
            width: 80%; } }
        @media screen and (min-width: 520px) {
          section.test .split .rightCallout .content h3 br {
            display: none; } }
      section.test .split .rightCallout .content p {
        line-height: 1.4rem;
        width: 90%;
        padding-top: 1.5rem;
        z-index: 3;
        position: relative;
        margin-bottom: 0.75rem; }
        @media screen and (max-width: 550px) {
          section.test .split .rightCallout .content p {
            width: 80%; } }
      section.test .split .rightCallout .content .socials {
        padding-top: 1rem;
        display: -ms-flexbox;
        display: flex;
        z-index: 3;
        position: relative; }
        section.test .split .rightCallout .content .socials a {
          padding-right: 15px; }
          section.test .split .rightCallout .content .socials a:last-of-type {
            padding-right: 0; }
          @media screen and (min-width: 900px) {
            section.test .split .rightCallout .content .socials a img {
              max-width: 50px; } }
        @media screen and (max-width: 997px) {
          section.test .split .rightCallout .content .socials {
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; }
            section.test .split .rightCallout .content .socials a {
              width: 34%;
              text-align: left;
              margin-bottom: 0.75rem; }
              section.test .split .rightCallout .content .socials a:nth-child(2) {
                padding-right: 0; } }
        @media screen and (max-width: 900px) {
          section.test .split .rightCallout .content .socials a {
            width: 25%; } }
        @media screen and (max-width: 545px) {
          section.test .split .rightCallout .content .socials a {
            width: 34%; } }
        @media screen and (max-width: 400px) {
          section.test .split .rightCallout .content .socials a {
            padding-right: 15px; }
            section.test .split .rightCallout .content .socials a img {
              width: 80%; }
            section.test .split .rightCallout .content .socials a:nth-child(2) {
              padding-right: 15px; }
            section.test .split .rightCallout .content .socials a:last-of-type {
              padding-right: 15px; } }

@media screen and (max-width: 640px) {
  section.test {
    padding: 2rem 0.75rem; }
    section.test .split {
      display: block; }
      section.test .split .leftCopy {
        padding-bottom: 3rem; }
        section.test .split .leftCopy p {
          padding-right: 0; }
      section.test .split .rightCallout {
        background-size: 100%; }
        section.test .split .rightCallout .content h3 {
          padding-right: 0; } }

@media screen and (min-width: 641px) and (max-width: 900px) {
  section.test {
    padding: 3rem 2.5rem; }
    section.test .split {
      display: block; }
      section.test .split .leftCopy {
        padding-bottom: 3rem; }
      section.test .split .rightCallout {
        background-size: 70%; } }

@media screen and (min-width: 901px) {
  section.test {
    padding: 5rem calc(50vw - 40rem) 2rem; } }

@media screen and (max-width: 1300px) {
  section.test {
    padding: 5rem 2rem 2rem; } }

section.header {
  background-color: #c8c8c8;
  position: relative;
  max-height: 600px;
  overflow: hidden; }
  section.header video {
    width: 200%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: -50%; }
    @media screen and (max-width: 767px) {
      section.header video {
        visibility: hidden; } }
    @media only screen and (device-width: 667px) and (device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      section.header video {
        visibility: hidden; } }
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
      section.header video {
        visibility: hidden; } }
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      section.header video {
        visibility: hidden; } }
  section.header .inner {
    position: relative;
    z-index: 2; }
    section.header .inner img {
      width: 100%;
      position: relative;
      z-index: 1;
      visibility: visible; }
      @media screen and (max-width: 767px) {
        section.header .inner img {
          visibility: visible; } }
      @media only screen and (min-width: 667px) and (device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        section.header .inner img {
          visibility: visible; } }
      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
        section.header .inner img {
          visibility: visible; } }
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        section.header .inner img {
          visibility: visible; } }
    section.header .inner video {
      width: 100%;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0; }
      @media screen and (max-width: 767px) {
        section.header .inner video {
          visibility: hidden; } }
      @media only screen and (min-width: 667px) and (device-height: 375px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        section.header .inner video {
          visibility: hidden; } }
      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
        section.header .inner video {
          visibility: hidden; } }
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        section.header .inner video {
          visibility: hidden; } }
  @media screen and (max-width: 640px) {
    section.header {
      padding: 2rem 0.75rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    section.header {
      padding: 3rem 2.5rem; } }
  @media screen and (min-width: 901px) {
    section.header {
      padding: 5rem calc(50vw - 40rem) 2rem; } }
  @media screen and (max-width: 1300px) {
    section.header {
      padding: 5rem 2rem 2rem; } }

section.thanks-header {
  background: #E9E9E9; }
  section.thanks-header .content {
    padding: 3rem;
    position: relative;
    background: #fff; }
    section.thanks-header .content .leftCornerTop {
      position: absolute;
      left: 0;
      top: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/top-left-ty.png") no-repeat left top;
      width: 1.7rem;
      height: 1.7rem;
      z-index: 2; }
    section.thanks-header .content .topMiddle {
      position: absolute;
      left: 0;
      top: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #E9E9E9;
      width: 100%;
      height: 2rem;
      background-size: 100%; }
    section.thanks-header .content .leftMiddle {
      position: absolute;
      left: 0;
      top: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/calloutBg.svg") no-repeat left center;
      width: 10%;
      height: 100%;
      background-size: auto 100%; }
    section.thanks-header .content .bottomMiddle {
      position: absolute;
      left: 0;
      bottom: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/bottom-middle-ty.png") repeat-x center bottom;
      width: 100%;
      height: 2rem; }
    section.thanks-header .content .rightMiddle {
      position: absolute;
      right: 0;
      top: 0;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      background: #fff url("../images/right-middle-ty.png") repeat-y right bottom;
      width: 1.7rem;
      height: 100%; }
    section.thanks-header .content .rightCornerTop {
      position: absolute;
      right: 0;
      top: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/top-right-ty.png") no-repeat right top;
      width: 1.7rem;
      height: 1.7rem;
      z-index: 2; }
    section.thanks-header .content .leftCornerbottom {
      position: absolute;
      left: 0;
      bottom: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/bottom-left-ty.png") no-repeat left bottom;
      width: 1.7rem;
      height: 2rem;
      z-index: 2; }
    section.thanks-header .content .rightCornerbottom {
      position: absolute;
      right: 0;
      bottom: 0;
      -ms-transform: translateY(0%);
          transform: translateY(0%);
      background: #fff url("../images/bottom-right-ty.png") no-repeat right bottom;
      width: 1.7rem;
      height: 2rem;
      z-index: 2; }
    section.thanks-header .content .top {
      display: -ms-flexbox;
      display: flex; }
      section.thanks-header .content .top .left {
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
            align-items: flex-start;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: end;
            justify-content: flex-end;
        display: none; }
        section.thanks-header .content .top .left.active {
          display: -ms-flexbox;
          display: flex; }
        section.thanks-header .content .top .left h3 {
          color: #E1251B;
          font-size: 2rem;
          z-index: 3;
          position: relative;
          font-family: 'Tungsten'; }
          @media screen and (max-width: 1000px) {
            section.thanks-header .content .top .left h3 {
              padding-right: 3rem; } }
        section.thanks-header .content .top .left h2 {
          color: #E1251B;
          font-size: 2.7rem;
          z-index: 3;
          position: relative;
          font-family: 'Tungsten'; }
          @media screen and (max-width: 1000px) {
            section.thanks-header .content .top .left h2 {
              padding-right: 3rem; } }
        @media screen and (max-width: 640px) {
          section.thanks-header .content .top .left {
            width: 40%; } }
        @media screen and (max-width: 455px) {
          section.thanks-header .content .top .left {
            width: 100%; }
            section.thanks-header .content .top .left h2 {
              padding-right: 1rem; } }
        @media screen and (max-width: 400px) {
          section.thanks-header .content .top .left h2 {
            font-size: 1.9rem; } }
      section.thanks-header .content .top .right {
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
            justify-content: flex-start; }
        section.thanks-header .content .top .right img {
          width: 20rem; }
          @media screen and (max-width: 600px) {
            section.thanks-header .content .top .right img {
              width: 100%; } }
        @media screen and (max-width: 640px) {
          section.thanks-header .content .top .right {
            width: 60%; } }
        @media screen and (max-width: 455px) {
          section.thanks-header .content .top .right {
            margin-top: 1rem; } }
      @media screen and (max-width: 455px) {
        section.thanks-header .content .top {
          -ms-flex-direction: column;
              flex-direction: column; } }
    section.thanks-header .content .bottom {
      display: -ms-flexbox;
      display: flex;
      padding-top: 2rem; }
      section.thanks-header .content .bottom .left {
        -ms-flex: 0 0 60%;
            flex: 0 0 60%; }
        section.thanks-header .content .bottom .left p {
          line-height: 2rem;
          font-size: 1.2rem;
          width: 95%;
          z-index: 3;
          position: relative;
          display: none; }
          section.thanks-header .content .bottom .left p.active {
            display: block; }
      section.thanks-header .content .bottom .middle {
        -ms-flex: 0 0 5%;
            flex: 0 0 5%;
        margin-right: 2rem; }
      section.thanks-header .content .bottom .right {
        -ms-flex: 0 0 35%;
            flex: 0 0 35%;
        padding-right: 4rem; }
        section.thanks-header .content .bottom .right .wrap h3 {
          color: #009ada;
          font-family: 'Tungsten';
          font-size: 1.9rem; }
        section.thanks-header .content .bottom .right .wrap p {
          padding: 1rem 0;
          font-size: 1rem; }
        section.thanks-header .content .bottom .right .wrap .socials {
          padding-top: 1rem;
          display: -ms-flexbox;
          display: flex;
          z-index: 3;
          position: relative; }
          section.thanks-header .content .bottom .right .wrap .socials a {
            padding-right: 15px; }
            section.thanks-header .content .bottom .right .wrap .socials a:last-of-type {
              padding-right: 0; }
          section.thanks-header .content .bottom .right .wrap .socials a {
            padding-right: 15px; }
            section.thanks-header .content .bottom .right .wrap .socials a:last-of-type {
              padding-right: 0; }
            @media screen and (min-width: 900px) {
              section.thanks-header .content .bottom .right .wrap .socials a img {
                max-width: 50px; } }
          @media screen and (max-width: 997px) {
            section.thanks-header .content .bottom .right .wrap .socials {
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap; }
              section.thanks-header .content .bottom .right .wrap .socials a {
                width: 34%;
                text-align: left;
                margin-bottom: 0.75rem; }
                section.thanks-header .content .bottom .right .wrap .socials a:nth-child(2) {
                  padding-right: 0; } }
          @media screen and (max-width: 900px) {
            section.thanks-header .content .bottom .right .wrap .socials a {
              width: 25%; } }
          @media screen and (max-width: 640px) {
            section.thanks-header .content .bottom .right .wrap .socials a img {
              width: 94%; }
            section.thanks-header .content .bottom .right .wrap .socials a:nth-child(2) {
              padding-right: 15px; }
            section.thanks-header .content .bottom .right .wrap .socials a:last-of-type {
              padding-right: 15px; } }
          @media screen and (max-width: 545px) {
            section.thanks-header .content .bottom .right .wrap .socials a {
              width: 44%; } }
          @media screen and (max-width: 400px) {
            section.thanks-header .content .bottom .right .wrap .socials a {
              padding-right: 15px; }
              section.thanks-header .content .bottom .right .wrap .socials a img {
                width: 80%; }
              section.thanks-header .content .bottom .right .wrap .socials a:nth-child(2) {
                padding-right: 15px; }
              section.thanks-header .content .bottom .right .wrap .socials a:last-of-type {
                padding-right: 15px; } }
  @media screen and (max-width: 640px) {
    section.thanks-header {
      padding: 2rem 0.75rem; }
      section.thanks-header .content .bottom {
        display: block; }
        section.thanks-header .content .bottom .middle {
          display: none; }
        section.thanks-header .content .bottom .right {
          padding-top: 2rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    section.thanks-header {
      padding: 3rem 2.5rem; }
      section.thanks-header .content .bottom {
        display: block; }
        section.thanks-header .content .bottom .middle {
          display: none; }
        section.thanks-header .content .bottom .right {
          padding-top: 2rem; } }
  @media screen and (min-width: 901px) {
    section.thanks-header {
      padding: 5rem calc(50vw - 40rem); } }
  @media screen and (max-width: 1300px) {
    section.thanks-header {
      padding: 5rem 2rem; } }

section.podcastSection .copy h1 {
  color: #009ADA;
  font-size: 3.9rem;
  font-family: 'Tungsten'; }

section.podcastSection .copy p {
  line-height: 2rem;
  padding-top: 2rem;
  width: 67%;
  padding-bottom: 3rem;
  font-size: 1.3rem;
  color: #5f6369; }

section.podcastSection .podcastsWrap {
  display: -ms-flexbox;
  display: flex; }
  section.podcastSection .podcastsWrap .podcast {
    background: #E4E4E4;
    -ms-flex: 0 0 24%;
        flex: 0 0 24%;
    margin-right: 10px; }
    section.podcastSection .podcastsWrap .podcast img {
      width: 100%;
      display: block; }
    section.podcastSection .podcastsWrap .podcast p {
      padding: 1rem;
      width: 100%;
      line-height: 1.3rem; }

@media screen and (max-width: 640px) {
  section.podcastSection {
    padding: 2rem 0.75rem; }
    section.podcastSection .copy p {
      width: 100%; }
    section.podcastSection .podcastsWrap {
      display: block; }
      section.podcastSection .podcastsWrap .podcast {
        margin-bottom: 2rem; } }

@media screen and (min-width: 641px) and (max-width: 900px) {
  section.podcastSection {
    padding: 3rem 2.5rem; }
    section.podcastSection .copy p {
      width: 100%; }
    section.podcastSection .podcastsWrap {
      display: block; }
      section.podcastSection .podcastsWrap .podcast {
        margin-bottom: 2rem; } }

@media screen and (min-width: 901px) {
  section.podcastSection {
    padding: 5rem calc(50vw - 40rem) 2rem; } }

@media screen and (max-width: 1300px) {
  section.podcastSection {
    padding: 5rem 2rem 2rem; } }

section.resources .bottomTitle h2 {
  padding-top: 4rem;
  color: #C7C9C8;
  font-family: 'Tungsten';
  font-size: 3.8rem;
  text-align: center; }

section.resources .copy h1 {
  color: #343741;
  font-size: 3.9rem;
  font-family: 'Tungsten'; }

section.resources .copy p {
  line-height: 1.4rem;
  padding-top: 2rem;
  width: 60%;
  padding-bottom: 3rem; }

section.resources .podcastsWrap {
  display: -ms-flexbox;
  display: flex; }
  section.resources .podcastsWrap .podcast {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex: 0 0 24%;
        flex: 0 0 24%;
    padding-top: 3rem;
    margin-right: 10px; }
    section.resources .podcastsWrap .podcast h2 {
      color: #343741;
      font-family: 'Tungsten';
      font-size: 2.5rem; }
    section.resources .podcastsWrap .podcast img.artwork {
      width: 100%;
      display: block; }
    section.resources .podcastsWrap .podcast p {
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
      padding: 2rem 0;
      width: 100%;
      line-height: 1.3rem; }
    section.resources .podcastsWrap .podcast .wrap {
      padding-top: 1rem; }
      section.resources .podcastsWrap .podcast .wrap a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
            justify-content: flex-end;
        color: #009ADA;
        text-decoration: none; }

@media screen and (max-width: 640px) {
  section.resources {
    padding: 2rem 0.75rem; }
    section.resources .copy p {
      width: 100%; }
    section.resources .podcastsWrap {
      display: block; }
      section.resources .podcastsWrap .podcast {
        margin-bottom: 2rem; } }

@media screen and (min-width: 641px) and (max-width: 900px) {
  section.resources {
    padding: 3rem 2.5rem; }
    section.resources .copy p {
      width: 100%; }
    section.resources .podcastsWrap {
      display: block; }
      section.resources .podcastsWrap .podcast {
        margin-bottom: 2rem; } }

@media screen and (min-width: 901px) {
  section.resources {
    padding: 5rem calc(50vw - 40rem); } }

@media screen and (max-width: 1300px) {
  section.resources {
    padding: 5rem 2rem; } }

section.hackableMe {
  display: -ms-flexbox;
  display: flex; }
  section.hackableMe .copy {
    -ms-flex: 0 0 65%;
        flex: 0 0 65%; }
    section.hackableMe .copy h1 {
      color: #009ADA;
      font-size: 3.9rem;
      font-family: 'Tungsten'; }
    section.hackableMe .copy h3 {
      font-family: 'Tungsten';
      font-size: 2.7rem; }
    section.hackableMe .copy p {
      line-height: 1.4rem;
      padding-top: 2rem;
      width: 80%;
      padding-bottom: 3rem; }
  section.hackableMe .podcastsWrap {
    -ms-flex: 0 0 35%;
        flex: 0 0 35%; }
    section.hackableMe .podcastsWrap .podcast {
      background: #E4E4E4;
      -ms-flex: 0 0 24%;
          flex: 0 0 24%;
      margin-right: 10px; }
      section.hackableMe .podcastsWrap .podcast img {
        width: 100%;
        display: block; }
      section.hackableMe .podcastsWrap .podcast p {
        padding: 1rem;
        width: 100%;
        line-height: 1.3rem; }
  @media screen and (max-width: 640px) {
    section.hackableMe {
      padding: 2rem 0.75rem;
      display: block; }
      section.hackableMe .copy p {
        width: 100%; }
      section.hackableMe .podcastsWrap {
        display: block; }
        section.hackableMe .podcastsWrap .podcast {
          margin-bottom: 2rem; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    section.hackableMe {
      padding: 3rem 2.5rem;
      display: block; }
      section.hackableMe .copy p {
        width: 100%; }
      section.hackableMe .podcastsWrap {
        display: block;
        width: 70%;
        margin: 0 auto; }
        section.hackableMe .podcastsWrap .podcast {
          margin-bottom: 2rem; } }
  @media screen and (min-width: 901px) {
    section.hackableMe {
      padding: 5rem calc(50vw - 40rem) 2rem; } }
  @media screen and (max-width: 1300px) {
    section.hackableMe {
      padding: 5rem 2rem 2rem; } }

section.about {
  background: #E9E9E9; }
  section.about .copy h1 {
    color: #000;
    font-size: 3.9rem;
    font-family: 'Tungsten'; }
  section.about .copy p {
    color: #5F6369;
    line-height: 2rem;
    padding-top: 2rem;
    width: 75%;
    padding-bottom: 3rem;
    font-size: 1.3rem;
    color: #5f6369; }
  section.about .copy a {
    text-decoration: none;
    color: #009ADA; }
  @media screen and (max-width: 640px) {
    section.about {
      padding: 2rem 0.75rem; }
      section.about .copy p {
        width: 100%; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    section.about {
      padding: 3rem 2.5rem; }
      section.about .copy p {
        width: 100%; } }
  @media screen and (min-width: 901px) {
    section.about {
      padding: 5rem calc(50vw - 40rem) 2rem; } }
  @media screen and (max-width: 1300px) {
    section.about {
      padding: 5rem 2rem 2rem; } }

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff; }
  body main {
    position: relative;
    z-index: 1;
    display: block;
    height: calc(100vh - 68px); }
    body main.active {
      z-index: 4; }
    body main.thankyou {
      height: auto; }
    body main form {
      height: 100%;
      position: relative; }
    body main .slider {
      background: #E9E9E9; }
    body main .slider, body main .slider2 {
      position: relative;
      -ms-transform: translateX(0%);
          transform: translateX(0%);
      top: 0;
      left: 0;
      z-index: 2; }
      body main .slider.active, body main .slider2.active {
        -ms-transform: translateX(0);
            transform: translateX(0); }
      body main .slider .slick-list, body main .slider2 .slick-list {
        height: 100% !important;
        outline: none !important;
        margin: 0 auto;
        max-width: 1200px; }
        body main .slider .slick-list .slick-track, body main .slider2 .slick-list .slick-track {
          height: 100%;
          outline: none !important; }
          body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
                flex-direction: column;
            min-height: 100vh;
            height: 100%;
            outline: none !important; }
            body main .slider .slick-list .slick-track .slick-slide.form, body main .slider2 .slick-list .slick-track .slick-slide.form {
              display: block; }
            @media screen and (max-width: 2250px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: 45% top; } }
            @media screen and (max-width: 1950px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: 35% top; } }
            @media screen and (max-width: 1750px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: 20% top; } }
            @media screen and (max-width: 1515px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: -5% top; } }
            @media screen and (max-width: 1110px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-size: 185%;
                background-position: 70% top; } }
            @media screen and (max-width: 768px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-size: 225%;
                background-position: 70% top; } }
            @media screen and (min-width: 2250px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: 55% top; } }
            @media screen and (min-width: 3000px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: 65% top; } }
            @media screen and (min-width: 3500px) {
              body main .slider .slick-list .slick-track .slick-slide, body main .slider2 .slick-list .slick-track .slick-slide {
                background-position: 75% top; } }
  body .container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 13%; }
  @media screen and (max-width: 640px) {
    body .container {
      padding: 0; }
      body .container .p {
        font-size: 2.1875rem; } }
  @media screen and (min-width: 901px) and (max-width: 1200px) {
    body .container {
      padding: 0 13%; }
      body .container .p {
        font-size: 2.1875rem; } }
  @media screen and (min-width: 1201px) {
    body .container {
      padding: 0; } }

h1, h2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700; }

button, input, textarea {
  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  outline: none;
  background: none; }

button {
  cursor: pointer; }

a > img {
  border: none; }
